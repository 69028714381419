<template>
  <div class="rc-container rc-font-small">
    
    <div class="rc-row rc-font-bold rc-font-medium">
      <div
        v-if="vehicle && vehicle.deleted == 'true'"
        class="rc-box-col-15 rc-text-centre">
        This vehicle has been removed and is no longer in use.
      </div>
    </div>

    <div class="rc-row">
      <div class="rc-box-col-6">
        <div class="rc-container">
          
          <div class="rc-row">
            <div class="rc-box-col-7">
              <VehicleImage v-if="vehicle" :defaultValue="vehicle" />
            </div>
            <div class="rc-box-col-8">
              <div class="container">
                <StringValueRow label="Name:" 
                  :value="decode(rcVehicle.name())" 
                  labelWidth="rc-box-col-6" 
                  valueWidth="rc-box-col-9"/>
                
                <StringValueRow label="Attributes:" 
                  :value="decode(rcVehicle.summary())" 
                  labelWidth="rc-box-col-6" 
                  valueWidth="rc-box-col-9"/>
                  
                <StringValueRow label="Colour:" 
                  :value="rcVehicle.colour()" 
                  labelWidth="rc-box-col-6" 
                  valueWidth="rc-box-col-9"/>
                
                <StringValueRow 
                  labelWidth="rc-box-col-6" valueWidth="rc-box-col-9"
                  label="Type"
                  :value="this.domain ? rcVehicle.category().find().name() : '' "
                  />
                
                <StringValueRow label="RGW(kg):"
                  v-if="rcVehicle.hasWeight()"
                  :value="rcVehicle.weight()" 
                  labelWidth="rc-box-col-6" 
                  valueWidth="rc-box-col-9" />
                
                <StringValueRow 
                  label="Brake Type:"
                  :value="rcVehicle.brakeTypeDisplay()"
                  labelWidth="rc-box-col-6" valueWidth="rc-box-col-9"
                  />
                
                <StringValueRow
                  v-if="this.domain && rcVehicle.isVehicle()"
                  label="Odometer:"
                  :value="rcVehicle.odometer()"
                  labelWidth="rc-box-col-6" valueWidth="rc-box-col-9"
                  />
                <StringValueRow 
                  v-if="this.domain && rcVehicle.isTrailer()"
                  label="Hubometer:"
                  :value="rcVehicle.odometer()"
                  labelWidth="rc-box-col-6" valueWidth="rc-box-col-9"
                  />
                
                <StringValueRow
                  v-if="rcVehicle.isDefectTypeGood()"
                  label="Last Result:"
                  :value="rcVehicle.defectTypeDisplay()"
                  labelWidth="rc-box-col-6" valueWidth="rc-box-col-9"
                  stylingValue="rc-input-good rc-text-centre"
                  />
                <StringValueRow
                  v-if="rcVehicle.isDefectTypeMinor()"
                  label="Last Result:"
                  :value="rcVehicle.defectTypeDisplay()"
                  labelWidth="rc-box-col-6" valueWidth="rc-box-col-9"
                  stylingValue="rc-input-minor rc-text-centre"
                  />
                <StringValueRow
                  v-if="rcVehicle.isDefectTypeMajor()"
                  label="Last Result:"
                  :value="rcVehicle.defectTypeDisplay()"
                  labelWidth="rc-box-col-6" valueWidth="rc-box-col-9"
                  stylingValue="rc-input-major rc-text-centre"
                  />
                 
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div class="rc-box-col-2 rc-text-centre rc-text-value">
        {{ decode(rcVehicle.plate()) }}
        <br />
        {{ decode( vehicle ? vehicle["jurisdiction"] : '') }}
      </div>
      
      <div class="rc-box-col-3 rc-text-centre rc-text-value">
        <Box>
          <Row>
            <Column>
              {{ decode( vehicle ? vehicle["vin"] : '') }}
            </Column>
          </Row>
        </Box>
      </div>
      
      <div class="rc-box-col-2 rc-text-centre rc-text-value">
        {{ vehicle ? toDate(vehicle["cvorDate"]) : '' }}
        <br>
        {{ vehicle ? decode(vehicle["cvorNumber"]) : '' }}
        <br>
        
        <div 
          v-if="rcVehicle.hasAnnualDateExpired()"
          style="background-color:#000000;color:#FFFFFF;">
            Annual Safety Required!
        </div>
        <div 
          v-else-if="rcVehicle.willAnnualDateExpireInWeeks(1)"
          style="background-color:#E90202;color:#FFFFFF;">
            Annual Safety required in {{ rcVehicle.annualDateDaysLeft() }} days
        </div>
        <div 
          v-else-if="rcVehicle.willAnnualDateExpireInWeeks(4)"
          style="background-color:#F39F42;color:#FFFFFF;">
            Annual Safety required in {{ rcVehicle.annualDateDaysLeft() }} days
        </div>
        <div 
          v-else-if="rcVehicle.willAnnualDateExpireInWeeks(8)"
          style="background-color:#3F82B6;color:#FFFFFF;">
            Annual Safety required in {{ rcVehicle.annualDateDaysLeft() }} days
        </div>
            
      </div>
      <div class="rc-box-col-2 rc-text-centre rc-text-value">
        {{ typeName(vehicle) }}
      </div>
    </div>
    
   <StringValueRow label="Schedule" :value="scheduleName(vehicle)" />
   <StringValueRow v-if="allowsChecklists()" label="Checklist" :value="checklistName(vehicle)" />
    
  </div>
</template>

<script>

import { mapGetters } from "vuex";

import ContentUtils from "@/utils/ContentUtils.js";
import ConstUtils from "@/utils/ConstUtils.js";
import DateUtils from "@/utils/DateUtils.js";
import StringUtils from "@/utils/StringUtils.js";

import VehicleImage from "@/views/portal/vehicles/VehicleImage.vue";

import Catelog from '@/domain/session/CanadianEnglish.js';
import Vehicle from '@/domain/model/vehicle/Vehicle.js';

import StringValueRow from '@/components/row/StringValueRow.vue';

import MC from "@/domain/session/MC.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

export default {
  name: "vehicle-details",
  components: {
    VehicleImage,
    StringValueRow,
    Box, Row, Column,
  },
  props: {
    vehicle: Object,
    isAdmin: {type: Boolean, default: false},
  },
  data() {
    return {
      catelog: Catelog.KEYS,
      MC: new MC(),
      Strings: StringUtils,
    };
  },
  watch: {
  },
  computed: {
    ...mapGetters([
      "auth_user",
      "auth_client",
      "auth_connected",
      "vehicles_found",
      "inspections_found",
      "companys_found",
      "schedules_found",
      "categorys_found",
      "domain",
    ]),
    rcVehicle: function() {
      if (this.domain) {
        return new Vehicle(this.domain, this.vehicle);
      }
      return new Vehicle(this.domain, {});
    }
  },
  methods: {
    decode: function (value) {
      return ContentUtils.unescape(value);
    },
    
    defectType: function (vehicle) {
      if (!vehicle) {
        return "";
      }
      
      return vehicle.defectType;
    },

    companyName: function (vehicle) {
      if (vehicle == null) {
        return "";
      }
      var id = vehicle[ConstUtils.FIELDS.COMPANY][ConstUtils.FIELDS.ID];
      var name = id;
      var company = this.companys_found.map[id];
      if (company) {
        name = company.name;
      }
      return name;
    },

    scheduleName: function (vehicle) {
      if (vehicle == null) {
        return "";
      }
      var rcVehicle = new Vehicle(this.domain, vehicle);
      var schedule = rcVehicle.schedule().find();
      var name = schedule.name();
      return ContentUtils.decode(name);
    },

    checklistName: function(vehicle) {
      if (vehicle == null) {
        return "";
      }
      var rcVehicle = new Vehicle(this.domain, vehicle);
      var checklist = rcVehicle.checklists().first().find();
      return ContentUtils.decode(checklist.name());
    },
    
    allowsChecklists: function() {
      if (this.domain) {
        return this.domain.session().company().find().features().find().allowsCheckLists();
      }
      return false;
    },

    typeName: function (vehicle) {
      if (vehicle == null) {
        return "";
      }
      
      var value = "";
      
      if (vehicle['make']) {
        value = vehicle['make'];
      }
      
      if (vehicle['model']) {
        value = value + " " + vehicle['model'];
      }
      return value;
    },

    categoryName: function (vehicle) {
      if (!vehicle) {
        return "";
      }
      var categoryPointer = vehicle.VehicleCategory[ConstUtils.FIELDS.ID];
      var category = this.categorys_found.map[categoryPointer];
      if (!category) {
        category = this.categorys_found.map[vehicle.VehicleCategory];
      }
      if (!category) {
        return "";
      }
      return this.decode(category.name);
    },

    toDate: function (dateValue) {
      return ContentUtils.decodeDate(dateValue);
    },

    toDays: function (dateValue) {
      var today = new Date();
      var nowTime = today.getTime();
      var cvorDate = new Date(dateValue);
      var cvorTime = cvorDate.getTime();
      var diffTime = nowTime - cvorTime;
      var days = Math.round(diffTime / (60000 * 60 * 24));
      return days;
    },

    toOdometer: function (vehicle) {
      if (vehicle.odometer) {
        return vehicle.odometer;
      }
      return "0";
    },
    
    toBrakingType: function (type) {
      if (type === "abs") {
        return "Air Brakes";
      }
      if (type === "ebs") {
        return "Electric Brakes";
      }
      if (type === "hbs") {
        return "Hydraulic Brakes";
      }
      return "";
    },
    
    toResultColour: function (result) {
      if (result == "None") {
        return "rc-input-good";
      }
      if (result == "Minor") {
        return "rc-input-minor";
      }
      if (result == "Major") {
        return "rc-input-major";
      }
      return "";
    },
    toResult: function (result) {
      if (result == "None") {
        return "Good";
      }
      if (result == "Minor") {
        return "Minor Defects";
      }
      if (result == "Major") {
        return "Major Defects";
      }
      return "";
    },

    toColour: function (dateValue) {
      var days = this.toDays(dateValue);
      var display = {
        hasMessage: false,
      };
      if (!dateValue) {
        return display;
      }
      if (days >= DateUtils.yearInDays() - 90) {
        display.hasMessage = true;
        display["message"] =
          "Annual Inspection Renewal in " +
          (DateUtils.yearInDays() - days) +
          " days!";
        display["colour"] = "background-color:#F9E425;";
      }
      if (days >= DateUtils.yearInDays() - 60) {
        display.hasMessage = true;
        display["message"] =
          "Annual Inspection Renewal in " +
          (DateUtils.yearInDays() - days) +
          " days!";
        display["colour"] = "background-color:#F39F42";
      }
      if (days >= DateUtils.yearInDays() - 30) {
        display.hasMessage = true;
        display["message"] =
          "Annual Inspection Renewal in " +
          (DateUtils.yearInDays() - days) +
          " days!";
        display["colour"] = "background-color:#E90202;color:#FFFFFF;";
      }
      if (days > DateUtils.yearInDays()) {
        display.hasMessage = true;
        display["message"] = "Annual Inspection Expired!";
        display["colour"] = "background-color:#000000;color:#FFFFFF;";
      }
      return display;
    },
  },
};
</script>