<template>
<Box>
    
    <ErrorRow :error="error" />

    <VehicleMenuRow 
      :showBorder="true" 
      :vehicleData="vehicleData" 
      :index="index" 
      :isAdmin="isAdmin" 
      v-on:action="action" 
      selected="details" />
    
    <VehicleDetailsHeader />
    
    <Row v-if="!isLoading">
      <Column :showBorder="true">
        <VehicleDetails :vehicle="vehicleData" :isAdmin="isAdmin"/>
      </Column>
    </Row>

    <LoadingRow :showBorder="true" v-if="isLoading">{{ loadingMessage }} </LoadingRow>
    
    <SpacerRow />
</Box>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ConstUtils from "@/utils/ConstUtils.js";
import ConnectionUtils   from '@/utils/ConnectionUtils.js';

import Vehicle from '@/domain/model/vehicle/Vehicle.js';
import VehicleMap from '@/domain/model/vehicle/VehicleMap.js';
import VehicleListFilter from "@/domain/model/vehicle/VehicleListFilter.js";

import ErrorRow from "@/components/row/ErrorRow.vue";

import VehicleDetails from "@/views/portal/vehicles/details/VehicleDetails.vue";
import VehicleDetailsHeader from "@/views/portal/vehicles/details/VehicleDetailsHeader.vue";
import VehicleMenuRow   from '@/portals/customer/operator/views/vehicle/menu/Menu.vue';

import Box from '@/portals/shared/library/box/Box.vue';
import Row from '@/portals/shared/library/box/Row.vue';
import Column from '@/portals/shared/library/box/Column.vue';

//import TitleRow from '@/portals/shared/library/title/TitleRow.vue';
import SpacerRow from '@/portals/shared/library/spacer/SpacerRow.vue';
import LoadingRow from '@/portals/shared/library/loading/LoadingRow.vue';


export default {
  name: "portal-vehicle-details",
  components: {
    ErrorRow,
    VehicleDetails,
    VehicleDetailsHeader,
    Box, Row, Column,
    LoadingRow, SpacerRow,
    VehicleMenuRow,
  },
  props: {
    isAdmin: {type: Boolean, default: false},
    index: {type: Number, default: 0},
  },
  data() {
    return {
      startComponent: false,
      id: this.$route.params["id"],
      error: null,
      isLoading: false,
      loadingMessage: "",
      vehicleData: null,
      isRefreshing: false,
    };
  },
  computed: {
    ...mapGetters([
      "vehicles_found",
      "companys_found",
      "types_found",
      "users_store",
      "makers_found",
      "categorys_found",
      "schedules_found",
      "auth_user",
      'auth_connected',
      'auth_socket_status',
      'signin_event',
      'auth_client',
      "domain",
    ]),
    vehicle: function () {
      var vehicle = this.vehicles_found.map[this.id];
      return vehicle;
    },
    vehicleObj: function () {
      return new Vehicle(this.domin, this.vehicle);
    }
  },
  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    }
  },
  mounted: function () {
    ConnectionUtils.ensureConnection(this);
  },
  methods: {
    ...mapActions([
                   'AuthStorage_updateCompany',
                   'addListenerAction',
                   'removeListenerAction',
                   'updateDomain',
                   ]),

    start: function() {
      this.isLoading = false;
      this.vehicleData = null;
      this.loadVehicle();
    },
    callback: function(msg) {
      this.loadingMessage = msg;
    },
    name: function() {
      if (this.vehicle) {
        return this.vehicleObj.name();
      }
      return '';
    },
    plate: function() {
      if (this.vehicle) {
        return this.vehicleObj.plate();
      }
      return '';
    },
    loadVehicle: function() {
      this.isLoading = true;
      this.loadingMessage = "Loading Vehicle..."
      var filter = new VehicleListFilter();
      filter
        .withPagination(20, 0)
        .withCompany(this.domain.session().company().id())
        .withIds([this.id])
        .done();

      var event = this.domain.events().vehicles().details(filter);
      event.send(this.loadVehicleDetailsListener);
    },
    loadVehicleDetailsListener: function(event) {
      if (event.error()) {
        this.error = event.error();
      } else {
        var payload = event.payload();
        var mapData = payload.valuesFor(VehicleMap.MODEL_NAME);
        var map = new VehicleMap(this.domain, mapData);
        this.vehicleData = map.getVehicle(this.id).data;
      }
      this.isLoading = false;
      this.loadingMessage = null;
      this.isRefreshing = false;
    },
    edit: function () {
      var params = {
        id: this.id,
      };
      this.$router.push({
        name: ConstUtils.ROUTES.VEHICLE.EDIT,
        params: params,
      });
    },
    refresh: function () {
      this.isRefreshing = true;
      this.loadVehicle();
    },
    details: function () {
      this.currentFilter = 'details';
    },
    inspections: function () {
      var params = {
          id: this.id,
        }
        this.$router.push({
          name: ConstUtils.ROUTES.VEHICLE.INSPECTIONS,
          params: params,
        })
    },
    insights: function() {
      //
    },
    isFilter: function (value) {
      return this.currentFilter === value;
    },
    action: function(name) {
      if (name === "refresh") {
        this.refresh();
      }
    },
  },
};
</script>