<template>
  <div class="rc-row rc-text-small rc-title-background">
    <div class="rc-box-col-6">
      Details
    </div>
    <div class="rc-box-col-2">
      Plate
    </div>
    <div class="rc-box-col-3">
      VIN
    </div>
    <div class="rc-box-col-2">
      Annual Safety
    </div>
    <div class="rc-box-col-2">
      Make/Model
    </div>
  </div>
</template>

<script>
export default {
  name: 'vehicle-details-header',
}
</script>